import { useEffect, useState } from "react";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import Web3 from "web3";

const App = () => {

  const [metamaskConnected, setMetamaskConnnected] = useState(false);
  const [account, setAccount] = useState();
  const [networkId, setNetworkId] = useState();
  const [isMetamask, setIsMetamask] = useState(true);

  useEffect(async () => {
    await loadWeb3().then((data) => {
      console.log("web3 data is ", data)
      if (data != false) {
        loadBlockchainData();
      }
    });
  });

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      // window.alert(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      setIsMetamask(false);
      return false;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    setNetworkId(networkId);

    if (accounts.length == 0) {
      setMetamaskConnnected(false);
    } else {
      setMetamaskConnnected(true);
      setAccount(accounts[0]);
    }

    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0)
      {
        setAccount(accounts[0]);
      }
      else
      {
        setAccount();
      } 
    });
    window.ethereum.on("networkChanged", (networkId) => {
      setNetworkId(networkId);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            {networkId != 137 && metamaskConnected && (
              <div className="network-err-msg">
                <h1>To use Premier, please switch to Polygon network.</h1>
              </div>
            )}
            {!isMetamask && (
              <div className="network-err-msg">
                <h1>Non-Ethereum browser detected. You should consider trying MetaMask!</h1>
              </div>
            )}
            <AppRoutes 
              metamaskConnected={metamaskConnected}
              setMetamaskConnnected={setMetamaskConnnected}
              account={account}
              networkId={networkId}
              isMetamask={isMetamask}
            />
          </AuthProvider>
        </BrowserRouter>
      </React.Fragment>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
