import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  AGENT_PROFILE_PAGE, 
  COMING_SOON_PAGE,
  CAR_LISTING_POSTS_PAGE,
  PLANE_LISTING_POSTS_PAGE,
  BUY_PREMIER_MATIC_PAGE,
  BUY_PREMIER_BTC_PAGE
} from 'settings/constant';

import {
  FaTwitter,
  FaDiscord,
  FaYoutube,
  FaFacebookSquare,
  FaTelegramPlane,
  FaInstagram
} from 'react-icons/fa';

const FooterMenu = (props) => {

  const { metamaskConnected, account, setMetamaskConnnected } = props;

  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${COMING_SOON_PAGE}`}>Real Estate</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${CAR_LISTING_POSTS_PAGE}`}>Cars</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${PLANE_LISTING_POSTS_PAGE}`}>Jets</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to="#about" onClick={()=> document.getElementById('about').scrollIntoView({ behavior: 'smooth' })}>About</NavLink>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <NavLink to={BUY_PREMIER_MATIC_PAGE}>Buy Premier/Matic</NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink to={BUY_PREMIER_BTC_PAGE}>Buy Premier/BTC</NavLink>
      </Menu.Item> */}
      <Menu.Item key="5">
        {metamaskConnected &&
          <NavLink to={`${AGENT_PROFILE_PAGE}`}>My NFTs</NavLink>
        }
      </Menu.Item>
    </Menu>
  );
};

export const SocialMenu = () => {  

  return (
    <Menu>
      <Menu.Item key="0">
        <a href="https://twitter.com/@PremierVacatio4" className="social_link" target="blank"> <FaTwitter /> </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="https://www.facebook.com/premiervacationsassets" className="social_link" target="blank"> <FaFacebookSquare /> </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="https://www.youtube.com/channel/UCArTtwSfxcWl9MKOc26c8RA" className="social_link" target="blank"> <FaYoutube /> </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a href="https://discord.gg/XKrx8hRZwH" className="social_link" target="blank"> <FaDiscord /> </a>
      </Menu.Item>      
      <Menu.Item key="4">
        <a href="https://t.me/premiervacations" className="social_link" target="blank"> <FaTelegramPlane /> </a>
      </Menu.Item>   
      <Menu.Item key="5">
        <a href="https://www.instagram.com/Premiervacations.io" className="social_link" target="blank"> <FaInstagram /> </a>
      </Menu.Item>      
    </Menu>
  );
};

export default FooterMenu;
