import React, { useContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  CAR_LISTING_POSTS_PAGE,
  PLANE_LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADD_HOTEL_PAGE,
  ADMIN_NFT_SETTINGS,
  ADMIN_NFT_AIRDROP,
  ADMIN_MINTED_NFTS,
  ADMIN_COLLECTIONS,
  ADMIN_OWNERS,
  ADMIN_PANEL,
  ADMIN_BOOKING,
  COMING_SOON_PAGE,
  BUY_PREMIER_MATIC_PAGE,
  BUY_PREMIER_BTC_PAGE,
  MINT_PAGE,
} from './settings/constant';

// protected route
function RequireAuth({ children }) {
  let { loggedIn } = useContext(AuthContext);
  let location = useLocation();
  if (!loggedIn) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
  }

  return children;
}

// public routes
const HomePage = React.lazy(() => import('containers/Home/Home'));
const ListingPage = React.lazy(() => import('containers/Listing/Listing'));
const CarListingPage = React.lazy(() => import('containers/Listing/CarListing'));
const PlaneListingPage = React.lazy(() => import('containers/Listing/PlaneListing'));
const SinglePageView = React.lazy(() =>
  import('containers/SinglePage/SinglePageView')
);
const MintPageView = React.lazy(() =>
  import('containers/SinglePage/MintView')
);
const AgentDetailsViewPage = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentDetailsViewPage')
);
const AgentItemLists = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentItemLists')
);
const AgentFavItemLists = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentFavItemLists')
);
const AgentContact = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentContact')
);
const PricingPage = React.lazy(() => import('containers/Pricing/Pricing'));
const PrivacyPage = React.lazy(() => import('containers/Privacy/Privacy'));
const SignInPage = React.lazy(() => import('containers/Auth/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('containers/Auth/SignUp/SignUp'));
const ForgetPasswordPage = React.lazy(() =>
  import('containers/Auth/ForgetPassword')
);
const NotFound = React.lazy(() => import('containers/404/404'));

const ComingSoon = React.lazy(() => import('containers/ComingSoon/ComingSoon'));

const BuyPremierMatic = React.lazy(() => import('containers/BuyPremier/BuyPremierMatic'));
const BuyPremierBtc = React.lazy(() => import('containers/BuyPremier/BuyPremierBtc'));

// protected route
const AddListingPage = React.lazy(() =>
  import('containers/AddListing/AddListing')
);
const AgentAccountSettingsPage = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentAccountSettingsPage')
);
const AdminNFTSettings = React.lazy(() =>
  import('containers/Agent/AccountSettings/AdminNFTSettings')
);
const AdminAirdrop = React.lazy(() =>
  import('containers/Agent/AccountSettings/AdminAirDrop')
);
const AdminCollections = React.lazy(() =>
  import('containers/Agent/AccountSettings/AdminCollections')
);
const AgentCreateOrUpdateForm = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentCreateOrUpdateForm')
);
const AgentPictureChangeForm = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentPictureChangeForm')
);
const ChangePassWord = React.lazy(() =>
  import('containers/Agent/AccountSettings/ChangePassWordForm')
);

const TokensByOwner = React.lazy(() =>
  import('containers/Agent/AccountSettings/TokensByOwner')
);

const Bookings = React.lazy(() =>
  import('containers/Agent/AccountSettings/AdminBookings')
);

const BookingDetail = React.lazy(() =>
  import('containers/Agent/AccountSettings/booking/BookingDetail')
);

const CollectionDetail = React.lazy(() =>
  import('containers/Agent/AccountSettings/AdminCollectionDetail')
);

const AppRoutes = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;

  return (
    <Routes>      
      <Route path={HOME_PAGE} 
              element={
                        <Layout 
                          metamaskConnected={metamaskConnected}
                          setMetamaskConnnected={setMetamaskConnnected}
                          account={account}
                        />
                      }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={CAR_LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <CarListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={PLANE_LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PlaneListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${SINGLE_POST_PAGE}/:slug`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView account={account}/>
            </React.Suspense>
          }
        />

        <Route
          path={MINT_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <MintPageView account={account}/>
            </React.Suspense>
          }
        />

        {/* Nested routes for agent page */}
        <Route
          path={AGENT_PROFILE_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AgentDetailsViewPage account={account}/>
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_PROFILE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentItemLists account={account}/>
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_FAVORITE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentFavItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_CONTACT}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentContact />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Nested routes */}
        <Route
          path={PRICING_PLAN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PricingPage />
            </React.Suspense>
          }
        />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOGIN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignInPage />
            </React.Suspense>
          }
        />
        <Route
          path={REGISTRATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path={FORGET_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgetPasswordPage />
            </React.Suspense>
          }
        />
        {/* Protected routes */}
        <Route
          path={ADD_HOTEL_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AddListingPage />
              </RequireAuth>
            </React.Suspense>
          }
        />
        <Route
          path={ADMIN_PANEL}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AgentAccountSettingsPage />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={ADMIN_PANEL}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentCreateOrUpdateForm />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_NFT_SETTINGS}
            element={
              <React.Suspense fallback={<Loader />}>
                <AdminNFTSettings
                  metamaskConnected={metamaskConnected}
                  account={account}
                />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_NFT_AIRDROP}
            element={
              <React.Suspense fallback={<Loader />}>
                <AdminAirdrop
                  metamaskConnected={metamaskConnected}
                  account={account}
                />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_COLLECTIONS}
            element={
              <React.Suspense fallback={<Loader />}>
                <AdminCollections />
              </React.Suspense>
            }
          />
          <Route
            path={`${ADMIN_COLLECTIONS}/:id`}
            element={
              <React.Suspense fallback={<Loader />}>
                <CollectionDetail 
                  metamaskConnected={metamaskConnected}
                  account={account}
                />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_MINTED_NFTS}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentPictureChangeForm />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_OWNERS}
            element={
              <React.Suspense fallback={<Loader />}>
                <ChangePassWord />
              </React.Suspense>
            }
          />
          <Route
            path={`${ADMIN_OWNERS}/:address`}
            element={
              <React.Suspense fallback={<Loader />}>
                <TokensByOwner />
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_BOOKING}
            element={
              <React.Suspense fallback={<Loader />}>
                <Bookings />
              </React.Suspense>
            }
          />
          <Route
            path={`${ADMIN_BOOKING}/:id`}
            element={
              <React.Suspense fallback={<Loader />}>
                <BookingDetail 
                  metamaskConnected={metamaskConnected}
                  account={account}
                />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path={COMING_SOON_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ComingSoon />
            </React.Suspense>
          }
        />
        <Route
          path={BUY_PREMIER_MATIC_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <BuyPremierMatic />
            </React.Suspense>
          }
        />
        <Route
          path={BUY_PREMIER_BTC_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <BuyPremierBtc />
            </React.Suspense>
          }
        />
        {/* end of Protected routes*/}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />        
      </Route>
    </Routes>
  );
}

export default AppRoutes;
